import { Company } from 'utilities'

export interface CompaniesLogosProps {
  arrayCompanies: Company[]
  className?: string
  id?: string
}

const CompaniesLogos = ({
  arrayCompanies,
  className,
  id,
}: CompaniesLogosProps) => {
  return (
    <div
      className={`grid grid-cols-2 md:grid-cols-4 gap-6 justify-items-center ${className}`}
      id={id}
    >
      {arrayCompanies.map((company, idx) => {
        return (
          <div
            className="flex justify-center items-center h-[51px] w-[155px] relative"
            key={idx}
          >
            <img
              src={`${process.env.REACT_APP_URL_S3}/images/logos/${company.img}`}
              alt={company.name}
              style={{ objectFit: 'cover' }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default CompaniesLogos
