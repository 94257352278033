import { Languages } from '../../constants'
import SpainFlag from '../Atoms/Flags/SpainFlag'
import Link from '../Atoms/Link/Link'
import { LinkProps } from '../Atoms/Link/useLink'

import BasqueFlag from 'components/Atoms/Flags/BasqueFlag'
import CataloniaFlag from 'components/Atoms/Flags/CataloniaFlag'

export interface LinkFlagProps extends LinkProps {
  language: Languages
  height?: number
}

const LinkFlag = ({
  disabled,
  className,
  onClick,
  selected,
  language,
  height,
}: LinkFlagProps) => {
  const getFlagComponent = (height: number) => {
    switch (language) {
      case Languages.SPANISH:
        return <SpainFlag height={height} data-testid="spanish-flag" />
      case Languages.BASQUE:
        return <BasqueFlag height={height} data-testid="baque-flag" />
      case Languages.CATALAN:
        return <CataloniaFlag height={height} data-testid="catalan-flag" />
      default:
        return null
    }
  }

  const flagComponent = getFlagComponent(height || 30)

  return (
    <Link
      className={className}
      onClick={onClick}
      disabled={disabled}
      selected={selected}
    >
      {flagComponent}
    </Link>
  )
}

export default LinkFlag
