import { HostNames } from './MainSection'
import { ButtonVariants, Languages } from '../../constants'
import Hamburguer from '../Atoms/Buttons/Hamburguer/Hamburguer'
import Link from '../Atoms/Link/Link'
import LinkFlag from '../Molecules/LinkFlag'

import { useEffect, useState } from 'react'

import classNames from 'classnames'
import RoundedButton from 'components/Atoms/Buttons/RoundedButton/RoundedButton'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

export interface HeaderProps {
  selected?: string
  onClick: (section: string) => void
  logo: string
  className?: string
}

const Header = ({ selected, onClick, logo, className }: HeaderProps) => {
  const hostName = window.location.hostname
  const [t, i18n] = useTranslation()
  const language = i18n.language
  const [isOpen, setIsOpen] = useState(false)
  const navClassname = classNames('bg-white', 'p-4', {
    'flex flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-center md:flex-row':
      isOpen,
    'hidden md:flex md:flex-row md:justify-center flex-col md:align-center':
      !isOpen,
  })
  const linkClassname = classNames({
    'ml-7': !isOpen,
  })

  const closeMenu = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isOpen &&
        !event.target.closest('.nav-container') &&
        !event.target.closest('.hamburger-button')
      ) {
        closeMenu()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  const languageBtn1 = () => {
    if (language === Languages.BASQUE) {
      return Languages.SPANISH
    } else if (language === Languages.CATALAN) {
      return Languages.SPANISH
    } else {
      return Languages.BASQUE
    }
  }

  const languageBtn2 = () => {
    if (language === Languages.CATALAN) {
      return Languages.BASQUE
    } else {
      return Languages.CATALAN
    }
  }

  const handleClick = (section: string) => {
    onClick(section)
    closeMenu()
  }
  return (
    <div className={className}>
      <div className="antialiased bg-white dark-mode:bg-white">
        <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-white nav-container">
          <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
            <div className="flex flex-row items-center justify-between p-4">
              <RouterLink to="/">
                <img
                  alt="logo"
                  src={`${process.env.REACT_APP_URL_S3}/images/${logo}`}
                  width={256}
                  height={39}
                />
              </RouterLink>
              <Hamburguer
                className="md:hidden focus:outline-none focus:shadow-outline"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
            <nav className={navClassname}>
              <Link
                selected={selected === 'success_stories'}
                onClick={() => handleClick('companies')}
              >
                {t('header.successStories')}
              </Link>
              <a
                href="https://blog.ciclogreen.com/"
                target="_blank"
                rel="noreferrer"
                className="flex justify-center"
              >
                <Link
                  selected={selected === 'blog'}
                  className={linkClassname}
                  onClick={() => {}}
                >
                  {t('header.blog')}
                </Link>
              </a>
              <Link
                selected={selected === 'how_it_works'}
                className={linkClassname}
                onClick={() => handleClick('how_it_works')}
              >
                {t('header.howWorks')}
              </Link>
              <div className="md:ml-10 md:mt-0 flex justify-center mt-5">
                <div className="flex flex-row ml-3">
                  <LinkFlag
                    language={languageBtn1()}
                    height={15}
                    onClick={() => i18n.changeLanguage(`${languageBtn1()}`)}
                  />
                  <LinkFlag
                    language={languageBtn2()}
                    height={15}
                    className="ml-3 mr-3"
                    onClick={() => i18n.changeLanguage(`${languageBtn2()}`)}
                  />
                </div>
              </div>
              <a
                className={`${linkClassname} md:mt-0 mt-5 w-full md:w-auto flex justify-center`}
                href={
                  hostName === HostNames.PTT
                    ? 'https://landing.ciclogreen.com/meetings/gregoriomagno/reunionciclogreen?utm_campaign=Plan de Transporte al Trabajo 2024&utm_source=PTT&utm_medium=landing&utm_content=page'
                    : 'https://landing.ciclogreen.com/meetings/gregoriomagno/reunionciclogreen?utm_campaign=Plan de Movilidad Sostenible 2024&utm_source=plan-movilidad&utm_medium=landing&utm_content=page'
                }
                target="_blank"
                rel="noreferrer"
              >
                <RoundedButton
                  variant={ButtonVariants.PRIMARY}
                  className={isOpen ? 'mt-2' : 'mt-0'}
                >
                  {t('header.contact')}
                </RoundedButton>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
