import { ButtonVariants, Languages } from '../../constants'
import RoundedButton from '../Atoms/Buttons/RoundedButton/RoundedButton'

import classNames from 'classnames'
import CompaniesLogos from 'components/Atoms/CompaniesLogos'
import Testimonials from 'components/Molecules/Testimonials'
import { useTranslation } from 'react-i18next'
import { selectedCompanies } from 'utilities'

export enum HostNames {
  // eslint-disable-next-line no-unused-vars
  PTT = 'plandetransportealtrabajo.es',
  // eslint-disable-next-line no-unused-vars
  PMS = 'plandemovilidadsostenible.com',
}

const MainSection = () => {
  const hostName = window.location.hostname
  const [t, i18n] = useTranslation()

  const bodyClassname = classNames(
    'container',
    'mx-auto',
    'mt-24',
    'space-y-4',
    'text-center',
    'md:space-y-10',
    'max-w-screen-xl',
    'md:px-28',
  )
  const titleClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-center',
    'text-2xl',
    'sm:text-5xl',
    'font-bold',
    'md:px-16',
    'text-black',
  )
  const subtitleClassName = classNames(
    'relative',
    'z-20',
    'w-full',
    'text-center',
    'text-lg',
    'sm:text-2xl',
    'font-regular',
    'md:px-44',
    'text-black',
  )

  return (
    <div className={bodyClassname}>
      <h1 color="text-blue-800" className={titleClassName}>
        {i18n.language === Languages.BASQUE && (
          <span className="text-blue-800">
            {hostName === HostNames.PTT ? t('titleCTAPTT') : t('titleCTAPMS')}
          </span>
        )}
        {t('title')}
        {i18n.language !== Languages.BASQUE && (
          <span className="text-blue-800">
            {hostName === HostNames.PTT ? t('titleCTAPTT') : t('titleCTAPMS')}
          </span>
        )}
      </h1>
      <h3 className={subtitleClassName}>
        {t('preSubtitle')}
        <span className="font-bold">{t('subtitleCTA')}</span>
        {t('postSubtitle')}
      </h3>
      <a
        href={
          hostName === HostNames.PTT
            ? 'https://landing.ciclogreen.com/meetings/gregoriomagno/reunionciclogreen?utm_campaign=Plan de Transporte al Trabajo 2024&utm_source=PTT&utm_medium=landing&utm_content=page'
            : 'https://landing.ciclogreen.com/meetings/gregoriomagno/reunionciclogreen?utm_campaign=Plan de Movilidad Sostenible 2024&utm_source=plan-movilidad&utm_medium=landing&utm_content=page'
        }
        target="_blank"
        rel="noreferrer"
      >
        <RoundedButton variant={ButtonVariants.PRIMARY} className="mt-6">
          {t('CTA')}
        </RoundedButton>
      </a>
      <div className="flex justify-center">
        <img
          src={`${process.env.REACT_APP_URL_S3}/images/imagen-inicio-PTT.jpg`}
          alt="Img-start-PTT"
          width={800}
          height={600}
        />
      </div>
      <CompaniesLogos arrayCompanies={selectedCompanies} />
      <Testimonials />
    </div>
  )
}

export default MainSection
