import classNames from 'classnames'

export interface ArrowProps {
  className?: string
  color?: string
  leftOrientation?: boolean
  height?: number
  width?: number
}

const Arrow = ({
  className,
  color,
  leftOrientation,
  height,
  width,
}: ArrowProps) => {
  const arrowClassName = classNames({
    'transform rotate-180': !leftOrientation,
    'transform rotate-0': leftOrientation,
  })
  return (
    <div className={`${className}  ${arrowClassName}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 45}
        height={height || 45}
        fill="none"
        viewBox="0 0 45 45"
        preserveAspectRatio="xMidYMid"
        xmlSpace="preserve"
      >
        <path
          fill={color || '#0AB496'}
          d="M0 22.5C0 10.074 10.074 0 22.5 0S45 10.074 45 22.5 34.926 45 22.5 45 0 34.926 0 22.5Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M31.875 22.098a.67.67 0 0 0-.67-.67H15.412l4.215-4.213a.672.672 0 0 0-.218-1.093.67.67 0 0 0-.73.145l-5.357 5.357a.671.671 0 0 0 0 .948l5.357 5.357a.67.67 0 0 0 .948-.949l-4.215-4.213h15.793a.67.67 0 0 0 .67-.67Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  )
}

export default Arrow
