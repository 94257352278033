import Testimonial from '../Atoms/Testimonial'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Arrow from 'components/Atoms/Arrow'
import { TestimonialProps } from 'models/Client'
import { Carousel } from 'react-responsive-carousel'
import { testimonials } from 'utilities'

const Testimonials = () => {
  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      showIndicators={false}
      interval={5000}
      animationHandler={'fade'}
      swipeable={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              left: '0px',
              top: '50%',
              transform: 'translateY(-60px)',
              zIndex: 2,
              position: 'absolute',
              cursor: 'pointer',
              opacity: 0.5,
            }}
          >
            <Arrow height={70} width={70} leftOrientation />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              right: '0px',
              top: '50%',
              transform: 'translateY(-60px)',
              zIndex: 2,
              position: 'absolute',
              cursor: 'pointer',
              opacity: 0.5,
            }}
          >
            <Arrow height={70} width={70} />
          </button>
        )
      }
    >
      {testimonials.testimonials.map((testimonial: TestimonialProps) => (
        <Testimonial
          client={testimonial.client}
          message={testimonial.message}
          key={testimonial.client.name}
        />
      ))}
    </Carousel>
  )
}

export default Testimonials
