import { Icon } from 'models/icons'

export default function CataloniaFlag({ width, height }: Icon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={width}
      height={height || 540}
      viewBox="0 0 810 500"
      xmlSpace="preserve"
      data-testid="flag"
    >
      <path fill="#FCDD09" d="M0 0h810v540H0z" />
      <path
        stroke="#DA121A"
        strokeWidth={60}
        d="M0 90h810m0 120H0m0 120h810m0 120H0"
      />
    </svg>
  )
}
