import { Client, Testimonials } from 'models/Client'

export interface Company {
  name: string
  img: string
}

export interface Testimonial {
  client: Client
  testimonial: string
}

export const selectedCompanies: Company[] = [
  {
    name: 'Ulma',
    img: 'ulma.png',
  },
  {
    name: 'Fagor',
    img: 'fagor.png',
  },
  {
    name: 'BBVA',
    img: 'bbva.png',
  },
  {
    name: 'Securitas Direct',
    img: 'securitas.png',
  },
]

export const companiesList: Company[] = [
  {
    name: 'Fagor',
    img: 'fagor.png',
  },
  {
    name: 'Ulma',
    img: 'ulma.png',
  },
  {
    name: 'IBM',
    img: 'ibm.png',
  },
  {
    name: 'Mahou San Miguel',
    img: 'mahou.png',
  },
  {
    name: 'Norauto',
    img: 'norauto.png',
  },
  {
    name: 'Sacyr',
    img: 'sacyr.png',
  },
  {
    name: 'Renfe',
    img: 'renfe.png',
  },
  {
    name: 'BBVA',
    img: 'bbva.png',
  },
  {
    name: 'BATZ',
    img: 'batz.png',
  },
  {
    name: 'Cosentino',
    img: 'cosentino.png',
  },
  {
    name: 'Securitas Direct',
    img: 'securitas.png',
  },
  {
    name: 'UAB',
    img: 'uab.png',
  },
  {
    name: 'Satec',
    img: 'satec.png',
  },
  {
    name: 'Deloitte',
    img: 'deloitte.png',
  },
  {
    name: 'Grupo Tragsa',
    img: 'tragsa.png',
  },
  {
    name: 'Fraternidad Muprespa',
    img: 'fraternidad.png',
  },
  {
    name: 'Parque Tecnológico de Asturias',
    img: 'parqueTecAsturias.png',
  },
  {
    name: 'PCT Cartuja',
    img: 'cartuja.png',
  },
  {
    name: 'Sopra Steria',
    img: 'sopra.png',
  },
  {
    name: 'Forever Green',
    img: 'foreverGreen.png',
  },
  {
    name: 'AMB',
    img: 'amb.png',
  },
  {
    name: 'ESIC',
    img: 'esic.png',
  },
  {
    name: 'Universidad de Navarra',
    img: 'uniNavarra.png',
  },
  {
    name: 'AYESA',
    img: 'ayesa.png',
  },
  {
    name: 'Bridgestone',
    img: 'bridgestone.png',
  },
  {
    name: 'Anses',
    img: 'anses.png',
  },
  {
    name: 'ATEXIS',
    img: 'atexis.png',
  },
  {
    name: 'Grupo Mondragon',
    img: 'mondragon.png',
  },
]

export const testimonials: Testimonials = {
  testimonials: [
    {
      client: {
        name: 'Lucía Sánchez',
        position: 'Técnico medioambiental de BATZ Group',
        company: 'BATZ Group',
        companyLogo: 'LOGO-BATZ.png',
        avatar: 'LUCIA-SANCHEZ.jpg',
      },
      message:
        '"Ofrecer un Plan de Movilidad Sostenible y saludable para nuestros empleados se ha considerado imprescindible. Destacamos el buen hacer de Ciclogreen acompañándonos durante todo el proceso, no solo como asesores expertos en la materia, sino dando respuesta a nuestras demandas personalizadas. Hemos desarrollado un diagnóstico bilingüe, llegando a obtener un porcentaje de participación significativo. Ahora conocemos nuestro punto de partida y los objetivos a alcanzar, eskerrik asko!".',
    },
    {
      client: {
        name: 'Óscar Urdangarín',
        position: 'Gestor de Sostenibilidad de Danobat Group',
        company: 'Danobat Group',
        companyLogo: 'LOGO-DANOBAT.png',
        avatar: 'oscar_urdangarin.jpg',
      },
      message:
        '"Nos planteamos la elaboración de un Plan de Movilidad Sostenible al centro de trabajo que redujera la huella que generaban los desplazamientos diarios del personal. Elaborar un Plan eficaz era una tarea ardua, diferente de lo que habitualmente hacemos. Sin embargo, hoy en día, gracias a la profesionalidad, experiencia y probado método de Ciclogreen, nuestro Plan de Movilidad es una realidad. Las encuestas personalizadas que elaboró Ciclogreen despertaron el interés de la gran mayoría de las personas de la empresa. Las respuestas obtenidas han permitido conocer la realidad y necesidad de los 3 polígonos industriales sonde se ubican las empresas del Grupo. Pero, nuestra mayor satisfacción ha sido el plan de acciones para fomentar la movilidad sostenible que Ciclogreen ha elaborado. Un plan original y personalizado a nuestra realidad, cuya característica principal es la de estar orientado a la generación de impacto relevante en nuestro objetivo de reducción de huella de carbono. Y, como la huella está en el origen de este plan, nada mejor que concluir con otra huella, la de Ciclogreen, ¡un partner que ha dejado huella en nuestra organización!".',
    },
    {
      client: {
        name: 'Lander Díaz',
        position: 'Presidente de la Fundación y Grupo ULMA',
        company: 'Grupo ULMA',
        companyLogo: 'LOGO-ULMA.png',
        avatar: 'lander-diaz.png',
      },
      message:
        '"Premiar la movilidad sostenible en ULMA está haciendo que los aparcamientos para bicis se nos estén quedando pequeños".',
    },
  ],
}
